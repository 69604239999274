import _uniqueId from 'lodash/uniqueId';
import _has from 'lodash/has';
import { API, ROLE_LNATSCHG } from './config';

export const SERVICE_KEY = '_vokarId';

export const LEGEND = Object.freeze({
  dvg: 'data:image/png;base64, iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAAABmJLR0QA/wD/AP+gvaeTAAAACXBIWXMAAA7EAAAOxAGVKw4bAAAAB3RJTUUH4AscDREDdYeJ7QAAAEBJREFUOMtj/P//PwM1ARMDlQELjDGHPZtip6b8nMrIgi5ArmEwB1Hdy6MGjho4auCogfjLQ0rLRFjRx0jtKgAAMF0SJ6l694kAAAAASUVORK5CYII=',
  lnatschg: 'data:image/png;base64, iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAAAAXNSR0IB2cksfwAAAAlwSFlzAAAOxAAADsQBlSsOGwAAAEVJREFUOI1jYaAyYKGZgWolDDMpNexWD0M6igtru0vINqy5tIeBgYGWXh41cNTAUQNHDcRpIKwIIgfc6mFIRzEQJkApAAArdwtvSAGtPgAAAABJRU5ErkJggg==',
  ffh: 'data:image/png;base64, iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAAAAXNSR0IB2cksfwAAAAlwSFlzAAAOxAAADsQBlSsOGwAAAH9JREFUOI3tlDEOQDAYRl8TWyeJqTcQt3B3nMAVajQ16YAaEEEHlIlvex1e/ryhEQ8vek2owYXKFIj9hRVSJq0xaQa9hvIsx9BtLpxnMcYG8FaoIL/L2id0IAAhYLjDB2EDBVIm9dzoCnuFJxr9Df+Ge+EHG4b8iWrquQqXh9CN+Ly9tNTn/jsAAAAASUVORK5CYII=',
  nsg: 'data:image/png;base64, iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAAAAXNSR0IB2cksfwAAAAlwSFlzAAAOxAAADsQBlSsOGwAAAGxJREFUOI1jYaAyYKGZgdPdPf9Taljmzu2MLOgCyPzp7p7/0cXQAUwNzEE4vUyKYchiWA0k1zCsBlJiGIaBlBqGYSClhmEYSKlhRBtIrGFEGUhsuBJl4GjCJkrNEEvYxJSJuNTALGJBF6AUAABjwnxR0qfjgQAAAABJRU5ErkJggg==',
  np: 'data:image/png;base64, iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAAAAXNSR0IB2cksfwAAAAlwSFlzAAAOxAAADsQBlSsOGwAAALpJREFUOI21lLERhDAMBI8Z5+RfDB2QE1AeATkdfDHkVOBP8LwsJHTwvDM0p0XW3Tjh4ZP+BxzX/DNtejVJF6rvcc1VrZ8zlsHW7APVQNmgYQCwDE2kSWZD2x2nJTVHU9oO2N4mi9HUQHkFa1+ExjdF70vDHM15DmWDZZLUUMDS4MG0hgIWmLfToqEmlJNZO5WacMJHgh0ZIKFUsBkDgBvBjgy4HOzIAHmj02DrN9F6I73a/qMvMNobeT767KK8M3qbvgAAAABJRU5ErkJggg==',
  flurstuecke: 'data:image/png;base64, iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAAAAXNSR0IB2cksfwAAAAlwSFlzAAAOxAAADsQBlSsOGwAAADtJREFUOI1jYaAyYKGZgXPmzPlPqWEpKSmMLOgC5BoGcxDtvDxq4KiBowaOGojTQErKRFjRx4IuQCkAAFxTDX6qJKHrAAAAAElFTkSuQmCC'
});

export const BASEMAPDE = Object.freeze(addUniqueId({
  url: 'https://sgx.geodatenzentrum.de/wmts_basemapde',
  id: 'de_basemapde_web_raster_grau',
  title: 'BASEMAP.DE',
  activeLayer: {
    id: 'de_basemapde_web_raster_grau'
  },
  maxScale: 2001,
  visible: true
}));

export const WMTS_NW_ALKIS = Object.freeze(addUniqueId({
  url: 'https://www.wmts.nrw.de/geobasis/wmts_nw_alkis',
  id: 'nw_alkis_grau',
  title: 'NW ALKIS',
  activeLayer: {
    id: 'nw_alkis_grau'
  },
  minScale: 2000,
  visible: true
}));

export const DOP20 = Object.freeze(addUniqueId({
  url: 'https://www.wmts.nrw.de/dop20',
  title: 'Luftbild',
  activeLayer: {
    id: 'dop20'
  }
}));

export const DOP_OVERLAY = Object.freeze(addUniqueId({
  url: 'https://www.wmts.nrw.de/geobasis/wmts_nw_dop_overlay',
  title: 'NW DOP OVERLAY',
  activeLayer: {
    id: 'nw_dop_overlay'
  }
}))

export const DVG = Object.freeze(addUniqueId({
  url: 'https://www.wms.nrw.de/geobasis/wms_nw_dvg',
  title: 'Verwaltungsgrenzen',
  sublayers: [
    {
      name: 'nw_dvg_rbz',
      title: 'Regierungsbezirke',
      visible: true,
      _legend: LEGEND.dvg
    },
    {
      name: 'nw_dvg_rbz_namen',
      title: 'Name der Regierungsbezirke',
      _legend: LEGEND.dvg
    }
  ]
}));

export const VOKAR = Object.freeze(addUniqueId({
  id: 'vokar',
  url: `${API.url}/api/v1/arcgis/rest/services/umwelt/vokar/MapServer`,
  title: 'Vorkaufsrecht',
  sublayers: [
    {
      id: 0,
      title: 'vokar_0',
      sublayers: [
        {
          id: 1,
          title: '§ 74 LNatSchG',
          sublayers: [
            {
              id: 6,
              title: 'Vorkaufsrechte',
              _legend: LEGEND.lnatschg,
              opacity: 0.7
            },
            {
            id: 2,
            title: 'Fachdaten',
            sublayers: [{
              id: 3,
              title: 'FFH-Gebiete',
              _legend: LEGEND.ffh
            }, {
              id: 4,
              title: 'Naturschutzgebiete',
              _legend: LEGEND.nsg
            }, {
              id: 5,
              title: 'Nationalparke',
              _legend: LEGEND.np
            }
            ]
          }]
        },
        {
          id: 7,
          title: 'Flurstücke',
          _legend: LEGEND.flurstuecke
        }
      ]
    },
    {
      id: 8,
      title: 'vokar_1',
      sublayers: [
        {
          id: 9,
          title: '§ 74 LNatSchG',
          sublayers: [
            {
              id: 14,
              title: 'Vorkaufsrechte',
              _legend: LEGEND.lnatschg,
              opacity: 0.7
            },
            {
            id: 10,
            title: 'Fachdaten',
            sublayers: [{
              id: 11,
              title: 'FFH-Gebiete',
              _legend: LEGEND.ffh
            }, {
              id: 12,
              title: 'Naturschutzgebiete',
              _legend: LEGEND.nsg
            }, {
              id: 13,
              title: 'Nationalparke',
              _legend: LEGEND.np
            }
            ]
          }]
        },
        {
          id: 15,
          title: 'Flurstücke',
          _legend: LEGEND.flurstuecke
        }
      ]
    },
    {
      id: 16,
      title: 'vokar_2',
      sublayers: [
        {
          id: 17,
          title: '§ 74 LNatSchG',
          sublayers: [
            {
              id: 22,
              title: 'Vorkaufsrechte',
              _legend: LEGEND.lnatschg,
              opacity: 0.7
            },
            {
            id: 18,
            title: 'Fachdaten',
            sublayers: [{
              id: 19,
              title: 'FFH-Gebiete',
              _legend: LEGEND.ffh
            }, {
              id: 20,
              title: 'Naturschutzgebiete',
              _legend: LEGEND.nsg
            }, {
              id: 21,
              title: 'Nationalparke',
              _legend: LEGEND.np
            }
            ]
          }]
        },
        {
          id: 23,
          title: 'Flurstücke',
          _legend: LEGEND.flurstuecke
        }
      ]
    },
    {
      id: 24,
      title: 'vokar_next',
      sublayers: [
        {
          id: 25,
          title: '§ 74 LNatSchG',
          _role: ROLE_LNATSCHG,
          sublayers: [
            {
              id: 30,
              title: 'Vorkaufsrechte',
              _legend: LEGEND.lnatschg,
              opacity: 0.7
            },
            {
            id: 26,
            title: 'Fachdaten',
            sublayers: [{
              id: 27,
              title: 'FFH-Gebiete',
              _legend: LEGEND.ffh
            }, {
              id: 28,
              title: 'Naturschutzgebiete',
              _legend: LEGEND.nsg
            }, {
              id: 29,
              title: 'Nationalparke',
              _legend: LEGEND.np
            }
            ]
          }]
        },
        {
          id: 31,
          title: 'Flurstücke',
          _legend: LEGEND.flurstuecke
        }
      ]
    }
  ]
}));

function addUniqueId (service, keyUniqueId = SERVICE_KEY, keyChildren = 'sublayers') {
  let obj = {
    ...service,
    [keyUniqueId]: _uniqueId()
  };
  if (_has(service, keyChildren)) {
    obj[keyChildren] = service[keyChildren].map(child => addUniqueId(child, keyUniqueId, keyChildren));
  }
  return obj;
}
